import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { css } from 'linaria'
import Layout from 'components/Layout'

import Section from '../components/Section'
import SectionContent from '../components/Section/Content'
import BlogCategoriesBar from '../elements/BlogCategoriesBar'
import FeaturedPost from '../components/Blog/FeaturedPost'

import rhythm from '../utils/rhythm'

import PostTile from '../components/Blog/PostTile'
import PostsSectionHeader from '../components/Blog/PostsSectionHeader'
import Newsletter from '../components/Blog/Newsletter'
import breakpoints from '../utils/tokens/breakpoints'
import { Subheading } from '../components/TextElements'

export default ({ location, data }) => (
  <Layout
    location={location}
    title="Bejamas Blog - All you need to know about JAMstack"
    description="Team of JAMstack freaks sharing stories, thoughts, and tips on modern web development and how to raise your web performance to the maximum. Both bor more and less technology oriented people."
    image="https://bejamas-care.now.sh/**Blog%20by%20Bejamas.**.png?theme=light-v1&md=1&fontSize=100px&images=https%3A%2F%2Fassets-bejamas.now.sh%2Fbrand%2Fsvg%2FBejamas_logo_black.svg"
    postHeaderComponent={<BlogCategoriesBar location={location} />}
    showCtaInHeader={true}
  >
    <FeaturedPost {...data.featuredPost} />
    <Section
      headerVariant="wide"
      header={
        <PostsSectionHeader to="/blog/case-studies/" text="Case studies" />
      }
    >
      <SectionContent
        columnGap="2"
        className={css`
          ${{
            maxWidth: '100%',
            marginTop: rhythm(1),
            [`@media (min-width: ${breakpoints.md})`]: {
              gridTemplateColumns: '1fr 1fr 1fr'
            },
            [`@media (min-width: ${breakpoints.lg})`]: {
              marginTop: rhythm(2),
              gridTemplateColumns: '2fr 1fr 1fr'
            },
            [`@media (min-width: ${breakpoints.xl})`]: {
              margin: `0 ${rhythm(3)}`
            }
          }};
        `}
      >
        {data.featuredCaseStudy && (
          <PostTile post={data.featuredCaseStudy} featured />
        )}
        {data.caseStudiesSelected &&
          data.caseStudiesSelected.edges.map(({ node }, i) => (
            <PostTile
              post={node}
              articleClassName={
                i === 1 &&
                css`
                  ${{
                    [`@media (min-width: ${breakpoints.lg})`]: {
                      marginTop: rhythm(12)
                    }
                  }};
                `
              }
            />
          ))}
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>Comprehensive guide to</Subheading>
          <h2>JAMstack SEO</h2>
        </>
      }
    >
      <SectionContent
        columnGap="2"
        columns="2"
        maxWidth="100%"
        className={css`
          ${{
            [`@media (min-width: ${breakpoints.xl})`]: {
              marginLeft: rhythm(3),
              marginRight: rhythm(3)
            }
          }};
        `}
      >
        {data.jamstackSeoPosts &&
          data.jamstackSeoPosts.edges.map(({ node }, i) => (
            <PostTile
              post={node}
              articleClassName={css`
                ${{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  alignItems: 'center',
                  columnGap: rhythm(3 / 2),
                  gridTemplateAreas: '"content cover"',
                  [`@media (max-width: ${breakpoints.md})`]: {
                    ['p']: {
                      marginRight: `calc(-100% - ${rhythm(3 / 2)})`
                    }
                  }
                }};
              `}
              coverClassName={css`
                ${{ marginBottom: 0 }};
              `}
            />
          ))}
      </SectionContent>
    </Section>
    <Section
      headerVariant="wide"
      variant="lightGray"
      header={<PostsSectionHeader to="/blog/dev-talks/" text="Dev talks" />}
    >
      <SectionContent
        columnGap="1.5"
        maxWidth="100%"
        className={css`
          ${{
            [`@media (min-width: ${breakpoints.md})`]: {
              gridTemplateColumns: '2fr 1fr 1fr'
            },
            [`@media (min-width: ${breakpoints.xl})`]: {
              margin: `0 ${rhythm(3)}`
            }
          }};
        `}
      >
        {data.featuredDevTalk && (
          <PostTile
            post={data.featuredDevTalk}
            featured
            articleClassName={css`
              ${{ gridRow: '1 / span 2' }};
            `}
          />
        )}
        {data.devTalks &&
          data.devTalks.edges.map(({ node }) => (
            <PostTile
              post={node}
              articleClassName={css`
                ${{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  alignItems: 'center',
                  columnGap: rhythm(3 / 2),
                  gridTemplateAreas: '"content cover"',
                  [`@media (min-width: ${breakpoints.md})`]: {
                    gridColumn: '2 / span 2'
                  },
                  [`@media (max-width: ${breakpoints.md})`]: {
                    ['p']: {
                      marginRight: `calc(-100% - ${rhythm(3 / 2)})`
                    }
                  }
                }};
              `}
              coverClassName={css`
                ${{ marginBottom: 0 }};
              `}
            />
          ))}
      </SectionContent>
    </Section>
    <Section
      headerVariant="wide"
      header={
        <PostsSectionHeader
          to="/blog/jamstack-universe/"
          text="JAMstack Universe"
        />
      }
    >
      <SectionContent
        columnGap="2"
        maxWidth="100%"
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
            [`@media (min-width: ${breakpoints.xl})`]: {
              margin: `0 ${rhythm(3)}`
            }
          }};
        `}
      >
        {data.jamstackUniverse &&
          data.jamstackUniverse.edges.map(({ node }) => (
            <PostTile post={node} />
          ))}
      </SectionContent>
    </Section>
    <Section
      headerVariant="wide"
      variant="lightGray"
      header={<PostsSectionHeader to="/blog/interviews/" text="Interviews" />}
    >
      <SectionContent
        columnGap="2"
        rowGap="2"
        maxWidth="100%"
        className={css`
          ${{
            [`@media (min-width: ${breakpoints.lg})`]: {
              gridTemplateColumns: '1fr 1fr'
            },
            [`@media (min-width: ${breakpoints.xl})`]: {
              margin: `0 ${rhythm(3)}`
            }
          }};
        `}
      >
        {data.interviews &&
          data.interviews.edges.map(({ node }) => (
            <PostTile
              post={node}
              articleClassName={css`
                ${{
                  display: 'grid',
                  gridTemplateColumns: '100%',
                  rowGap: rhythm(1),
                  gridTemplateAreas: '"cover" "content"',
                  [`@media (min-width: ${breakpoints.md})`]: {
                    gridTemplateColumns: '160px auto',
                    gridTemplateAreas: '"cover content"',
                    columnGap: rhythm(3 / 2)
                  }
                }};
              `}
              seeMore={false}
              coverClassName={css`
                ${{ marginBottom: 0 }};
              `}
            />
          ))}
      </SectionContent>
    </Section>
    <Section
      headerVariant="wide"
      header={<PostsSectionHeader text="Most read" />}
    >
      <SectionContent
        columnGap="2"
        maxWidth="100%"
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
            [`@media (min-width: ${breakpoints.xl})`]: {
              margin: `0 ${rhythm(3)}`
            }
          }};
        `}
      >
        {data.mostRead &&
          data.mostRead.edges.map(({ node }) => <PostTile post={node} />)}
      </SectionContent>
    </Section>
    <Newsletter />
  </Layout>
)

export const query = graphql`
  query BlogPageQuery {
    featuredPost: markdownRemark(frontmatter: { inBlogHero: { eq: true } }) {
      frontmatter {
        title
        excerpt
        image {
          childImageSharp {
            fluid(maxWidth: 445) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
      }
      fields {
        slug
      }
    }
    featuredCaseStudy: markdownRemark(
      frontmatter: {
        featured: { eq: true }
        category: { regex: "/case-studies/" }
        inBlogHero: { ne: true }
      }
    ) {
      frontmatter {
        title
        excerpt
        image {
          childImageSharp {
            fluid(maxWidth: 465) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
      }
      fields {
        slug
      }
    }
    caseStudiesSelected: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: {
          slug: {
            in: [
              "/blog/seomonitor-case-study/"
              "/blog/case-study-just-technologies/"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 270) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    jamstackSeoPosts: allMarkdownRemark(
      filter: {
        fields: {
          slug: { in: ["/blog/jamstack-seo-guide/", "/blog/content-seo/"] }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 465) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    caseStudies: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          category: { regex: "/case-studies/" }
          featured: { ne: true }
          inBlogHero: { ne: true }
        }
      }
      limit: 2
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 270) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    featuredDevTalk: markdownRemark(
      frontmatter: {
        featured: { eq: true }
        category: { regex: "/dev-talks/" }
        inBlogHero: { ne: true }
      }
    ) {
      frontmatter {
        title
        excerpt
        image {
          childImageSharp {
            fluid(maxWidth: 465) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
      }
      fields {
        slug
      }
    }
    devTalks: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          category: { regex: "/dev-talks/" }
          featured: { ne: true }
          inBlogHero: { ne: true }
        }
      }
      limit: 2
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 270) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    jamstackUniverse: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          category: { regex: "/jamstack-universe/" }
          inBlogHero: { ne: true }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    interviews: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          category: { regex: "/interviews/" }
          inBlogHero: { ne: true }
        }
      }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 160) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    mostRead: allMarkdownRemark(
      filter: { frontmatter: { category: { regex: "/dev-talks/" } } }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
          }
          fields {
            slug
          }
        }
      }
    }
    posts: allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
