import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { css } from 'linaria'
import rhythm from '../../utils/rhythm'
import { colors } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'
import { ReactComponent as ArrowIcon } from '../../assets/svg/icons/arrow-bottom.svg'
import cx from '../../utils/cx'
import delve from 'dlv'

const bar = css`
  background: rgba(217, 231, 239, 0.25);

  ul {
    display: flex;
    list-style: none;
    justify-content: center;
    margin: 0;
    flex-wrap: wrap;

    @media (max-width: 1023px) {
      li {
        margin: 0;
        padding: 0;
        width: 100%;

        a {
          padding: ${rhythm(2 / 3)} 8vw;
          display: block;
          border-top: 1px solid rgba(0, 0, 0, 0.05);
        }

        &:not(.current) {
          display: none;

          &.is-open {
            display: block;
          }
        }

        &.current {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: ${rhythm(2 / 3)} 8vw;
        }
      }
    }

    @media (min-width: ${breakpoints.md}) {
      li a,
      li.current {
        padding: ${rhythm(2 / 3)} 4vw;
      }
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      text-align: center;

      li {
        margin: 0;
        padding: ${rhythm(2 / 3)} ${rhythm(1)};

        a {
          padding: 0;
        }
      }

      .current {
        display: none;
      }
    }
  }
`

const link = css`
  text-decoration: none;
  color: ${colors.text.lightGray};

  &:hover {
    color: ${colors.text.darkGray};
  }
`

const active = css`
  color: ${colors.accent.purple};

  &:hover {
    color: ${colors.accent.purple};
  }
`

const arrowIcon = css`
  width: 24px;
  height: 8px;
`

const arrowOpen = css`
  transform: rotate(180deg);
`

const hideOnMobile = css`
  @media (max-width: 1023px) {
    display: none !important;
  }
`

const BlogCategoriesBar = ({ location, currentCategory }) => {
  const [open, setOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query blogCategoriesQuery {
      categories: allBlogCategoriesYaml {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `)

  let arrowClasses = cx({
    [arrowIcon]: true,
    [arrowOpen]: open
  })

  return (
    <div className={bar}>
      <ul>
        <li className="current" onClick={() => setOpen(!open)}>
          {currentCategory ? currentCategory.title : 'All'}
          <ArrowIcon className={arrowClasses} viewBox="0 0 10 6" />
        </li>
        <li
          className={cx({
            ['is-open']: open,
            [hideOnMobile]: !Boolean(currentCategory)
          })}
        >
          <Link to={`/blog/`} activeClassName={active} className={link}>
            All
          </Link>
        </li>
        {data.categories.edges.map(({ node }) => (
          <li
            className={cx({
              ['is-open']: open,
              [hideOnMobile]: node.slug === delve(currentCategory, 'slug')
            })}
          >
            <Link
              to={`/blog/${node.slug}/`}
              activeClassName={active}
              className={link}
            >
              {node.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BlogCategoriesBar
